import { Vue, Component } from "vue-property-decorator";
import { scrollToEl } from "@helpers";

@Component({
  components: {
    FAQ: () => import("@/components/FAQ/FAQ.vue"),
  },
})
export default class Navbar extends Vue {
  private showedFAQDialog = false;
  private isFixed = false;

  private onClickNavItem(event: Event) {
    const navEl = event.target as HTMLButtonElement;
    const elementId = navEl.dataset.elementId;
    const href = navEl.dataset.href;

    if (elementId === "faq") {
      this.showedFAQDialog = true;
    } else if (elementId && document.getElementById(elementId)) {
      this.scrollToEl(elementId);
    } else if (href) {
      window.location.href = href;
    }
  }

  private scrollToEl(id: string) {
    scrollToEl(id);
  }

  private mounted() {
    const autoScrollTimeoutId = window.setTimeout(() => {
      const elementId = this.$route.hash.replace("#", "");

      this.scrollToEl(elementId);
    }, 500);

    const onScroll = () => {
      this.isFixed = window.scrollY > 200;
    };

    onScroll();

    window.addEventListener("scroll", onScroll);

    this.$once("hook:beforeDestroy", () => {
      window.clearTimeout(autoScrollTimeoutId);
      window.removeEventListener("scroll", onScroll);
    });
  }
}
